import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { capitalize } from '../../helpers/utils';
import { motion } from 'framer-motion';
import { IoIosArrowDown } from 'react-icons/io';
import { handleGlobalModal } from '../../ducks/Global/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MealPlanLoading } from '../../components/MacroNutrientCalculatorComponent/customMealPlanLoading';
import { IoIosArrowBack } from 'react-icons/io';
import { lowCalories } from '../../data/warnings';
import { GenerateMealplanModal } from './generateMealplanModal';

export const MacroResults = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isGeneratingMealPlan, setIsGeneratingMealPlan] = useState(false);
  const [cardOpen, setCardOpen] = useState({
    bmr: false,
    tdee: false,
    macros: false,
    water: false,
  });

  const userGender = useSelector(
    (state) => state.global.macronutrientAnswers.gender
  );

  const macroResults = useSelector((state) => state.global.macroResults);

  const isMobileView = location.state?.isMobileView || false;

  const selectedGoal = useMemo(() => {
    return macroResults?.macrosForEachGoal
      ? Object.entries(macroResults.macrosForEachGoal).find(
          ([, goal]) => goal.isSelected
        )
      : null;
  }, [macroResults]);

  useEffect(() => {
    if (!macroResults || !selectedGoal) return;
    let isLowCalories =
      macroResults && macroResults.bmr > selectedGoal[1]?.calories;
    if (
      isLowCalories &&
      window.location.pathname === '/tools/macronutrient-calculator/results'
    ) {
      dispatch(
        handleGlobalModal(
          true,
          lowCalories(macroResults.bmr, selectedGoal[1].calories, userGender)
        )
      );
    }
  }, [macroResults, selectedGoal, dispatch, userGender]);

  if (macroResults && !macroResults.bmr) {
    return <p>Loading results...</p>;
  }

  if (!macroResults || !macroResults.macrosForEachGoal) {
    return <div>Error: Macro results not available. Please try again.</div>;
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const sortedGoals =
    macroResults &&
    Object.entries(macroResults.macrosForEachGoal).sort(
      ([, goalA], [, goalB]) =>
        goalA.isSelected === goalB.isSelected ? 0 : goalA.isSelected ? -1 : 1
    );

  const recommendedGoal =
    macroResults && sortedGoals.find(([, goal]) => goal.isSelected);

  return isGeneratingMealPlan ? (
    <MealPlanLoading onComplete={() => setIsGeneratingMealPlan(false)} />
  ) : (
    <motion.div
      className='macro-results-page'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      {macroResults && (
        <GenerateMealplanModal
          macroResults={macroResults}
          isMobileView={isMobileView}
          isAdvancedMode={false}
        />
      )}
      {macroResults && (
        <div className='landing-page black-background'>
          <motion.div
            className='results-container'
            variants={containerVariants}
          >
            <motion.div
              className='flex align-center space-between mb-20'
              variants={itemVariants}
            >
              <p className='small-title text-center text-gradient'>
                {t('results.congrats')}
              </p>
              <div className='flex align-center' style={{ cursor: 'pointer' }}>
                <IoIosArrowBack style={{ marginRight: '3px' }} />
                <p
                  className='back-button '
                  onClick={() => navigate('/tools/macronutrient-calculator')}
                >
                  {t('buttons.calculateAgain')}
                </p>
              </div>
            </motion.div>
            <motion.div
              className='global-card dark mb-10'
              variants={itemVariants}
            >
              <div className='mb-20'>
                <div className='grid'>
                  <p className='eyebrow-text white mb-10'>
                    For {capitalize(recommendedGoal[0].split('-').join(' '))}{' '}
                    {`${recommendedGoal[0] !== 'maintain-weight' ? '~' : ''}`}
                    {recommendedGoal[1].description}
                  </p>
                </div>
              </div>

              <div className='mb-20'>
                <div className='grid gap-10'>
                  {recommendedGoal && (
                    <div
                      key={recommendedGoal[0]}
                      className='macro-card'
                      style={{
                        position: 'sticky',
                        top: '20px',
                        zIndex: 10,

                        borderRadius: '20px',
                        padding: `${isMobileView ? '18px 10px' : '18px'}`,
                      }}
                    >
                      <span
                        className='yellow-label recommended'
                        style={{ width: isMobileView && '100%' }}
                      >
                        ✨Recommended Daily✨
                      </span>
                      <div>
                        <div className='flex space-between'>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].calories}
                            </p>
                            <p className='subtitle white'>Calories</p>
                          </div>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].protein}g
                            </p>
                            <p className='subtitle white'>Protein</p>
                          </div>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].carbs}g
                            </p>
                            <p className='subtitle white'>Carbs</p>
                          </div>
                          <div className='grid justify-center'>
                            <p className='macro-number bold'>
                              {recommendedGoal[1].fat}g
                            </p>
                            <p className='subtitle white'>Fat</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <Button
                  onClick={() =>
                    dispatch({
                      type: 'HANDLE_GENERATE_MEALPLAN_MODAL',
                      payload: true,
                    })
                  }
                  size='large'
                  type='primary'
                  className='landing-button  generate-meal-plan gradient full-width-button gradient'
                >
                  Generate Your Meal Plan
                </Button>
              </div>
            </motion.div>
            <motion.div
              className='global-card dark clickable mb-10'
              variants={itemVariants}
              onClick={() =>
                setCardOpen({ ...cardOpen, bmr: !cardOpen['bmr'] })
              }
            >
              <div className='flex space-between align-center'>
                <p className='eyebrow-text white mb-10'>
                  Your BMR (Basal Metabolic Rate)
                </p>
                <IoIosArrowDown className='mb-10' />
              </div>
              <p
                className='macro-number bold'
                style={{ marginBottom: cardOpen['bmr'] && '10px' }}
              >
                {macroResults.bmr}
              </p>

              {cardOpen['bmr'] && (
                <p className='subtitle'>
                  BMR represents the number of calories your body needs to
                  perform basic functions like breathing, circulation, and cell
                  production while at rest. It's the minimum amount of energy
                  required to sustain life, and it varies based on factors like
                  age, weight, gender, and muscle mass.
                </p>
              )}
            </motion.div>

            <motion.div
              className='global-card dark clickable mb-10'
              variants={itemVariants}
              onClick={() =>
                setCardOpen({ ...cardOpen, tdee: !cardOpen['tdee'] })
              }
            >
              <div className='flex space-between align-center'>
                <p className='eyebrow-text white mb-10'>
                  Your TDEE (Total Daily Energy Expenditure)
                </p>
                <IoIosArrowDown />
              </div>
              <p
                className='macro-number bold'
                style={{ marginBottom: cardOpen['tdee'] && '10px' }}
              >
                {macroResults.maintenanceCalories}
              </p>
              {cardOpen['tdee'] && (
                <p className='subtitle'>
                  TDEE is the total number of calories your body burns in a day,
                  including all physical activities such as exercise, walking,
                  and even digestion. It combines your BMR with your activity
                  level to determine how many calories you need to maintain your
                  current weight.
                </p>
              )}
            </motion.div>
          </motion.div>
        </div>
      )}
    </motion.div>
  );
};
