import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export const MealPlanLoading = ({ onComplete }) => {
  const [step, setStep] = useState(0);

  const loadingSteps = [
    'Customizing meals to fit your macros...',
    'Balancing nutrients across your day...',
    'Finalizing your personalized meal plan...',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevStep) => {
        if (prevStep < loadingSteps.length - 1) {
          return prevStep + 1;
        } else {
          clearInterval(interval);
          onComplete(); // Trigger the next action when loading completes
          return prevStep;
        }
      });
    }, 2000); // 2 seconds per step

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      className='meal-plan-loading'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 80px)',
        backgroundColor: '#000',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      <motion.div
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{
          repeat: Infinity,
          duration: 2,
          ease: 'linear',
        }}
        style={{
          fontSize: '50px',
        }}
      >
        🍳
      </motion.div>
      <motion.div
        key={step}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.5 }}
        style={{ marginBottom: '20px', fontSize: '18px' }}
      >
        {loadingSteps[step]}
      </motion.div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '60%',
        }}
      >
        {loadingSteps.map((_, index) => (
          <motion.div
            key={index}
            initial={{ width: 0 }}
            animate={{ width: step >= index ? '100%' : '0%' }}
            transition={{ duration: 0.5 }}
            style={{
              height: '5px',
              backgroundColor: '#00bfb3',
              margin: '0 5px',
              borderRadius: '5px',
            }}
          />
        ))}
      </div>
    </motion.div>
  );
};
